
.ClozeQuiz .SelectSpan, .SelectSpan {
  



  .choices {


    &.-show-correctness.-correct {
      background-color: theme.colors.main-green-two;
      .drop-down {
        border-color: theme.colors.main-green;
        background-color: theme.colors.main-green-two;
        .list {
          background-color: theme.colors.main-green-two;
          border-color:theme.colors.main-green;
        }
      }
      
    }
    &.-show-correctness:not(.-correct) {
      background-color: theme.colors.wrong-red-two;
      .drop-down {
        border-color: theme.colors.wrong-red;
        background-color: theme.colors.wrong-red-two;
        .list {
          border-color:theme.colors.wrong-red;
          background-color: theme.colors.wrong-red-two;
        }
      }

      
    }
  }

}
