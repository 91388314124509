.main-navigation {
    position: sticky;
    background-color: #fff;
    top: 0px;
    width: 100%;
    height: 60px;
    color:theme.colors.main-purple;
    z-index: 20;

    &.-scrolling {
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
        .logo {
            visibility:visible;
        }
    }

    .logo {
        visibility: hidden;
        margin-left:30px;
        .svg-icon {
            width: 264px;
        }
    }

    .back {
        cursor: pointer;

        ._hidden {
            display: none;
        }
    }

    .languages {
        position:relative;
        margin-right: 16px;
        //padding: 5px;
        ._hidden {
            display:none;
        }
        &:hover {
            cursor:pointer;
        }
        &.-open {
            .menu {
                position: absolute;
                text-align: left;
                background-color: #fff;
                width: 100%;
                // padding: 5px;
            }
        }
    }

    .navi-button {
        &:hover {
            cursor:pointer;
        }

        margin-right: 16px;
        font-size: rem(24px);
        
        .svg-icon {
            width: 32px;
            height: 32px;
        }
    }
    .navi-close-button {
        &:hover {
            cursor:pointer;
        }
        text-align: right;
        color: #fff;
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100vh;
        padding-top: 16px;
        padding-right: 16px;
        background-color: theme.colors.main-purple;
        >.items {
            >.item {
                // hack for hover...
                text-align: left;
                height: 54px;
                line-height: 54px;
                padding-left: 32px;
                padding-right: 16px;
                margin-right: -16px;
                &:hover {
                    cursor:pointer;
                    background-color: theme.colors.secondary-purple;
                }
            }
        }
    }


    
    &.-open {
        display: block;
        &.navi-close-button {
            display: block;
        }
    }

    &.-closed {
        >.navi-close-button {
            display: none;
        }
    }
    
}