.quiz-presenter {
    .info-text {
        border-bottom: 2px solid black;
        padding: 0px 2px;
        cursor: pointer;
    }
    .image-container {
        &._hidden {
            display: none;
        }

        position: relative;
        display:inline-block;

        .quiz-image {
            max-width: 100%;
            max-height: 424px;
        }

        .magnify >.svg-icon {
            color: rgba(256,256,256, 0.6);
            position: absolute;
            bottom: 16px;
            right: 16px;
            cursor: pointer;
            width: 42px;
            height: 42px;
        }

    }

    .dialog {
        >.correct {
            color: theme.colors.main-green;
            border: 1px solid theme.colors.main-green;
            background-color: theme.colors.main-green-two;
        }
        >.incorrect {
            color: theme.colors.wrong-red;
            border: 1px solid theme.colors.wrong-red;
            background-color: theme.colors.wrong-red-two;
        }
        
        .smiley {
            width: 42px;
            height: 42px;
            fill: currentColor;
            // display: inline-block;
        }
        .text {
            display: inline-block;
        }

        >.noanswer {
            border: 1px solid theme.colors.wrong-red;
            background-color: theme.colors.wrong-red-two;
        }
        
        // >.completed {
        //     border: 1px solid theme.colors.main-green;
        //     background-color: theme.colors.main-green-two;
        //     .close {
        //         color: theme.colors.wrong-red;
        //         text-decoration: underline;
        //         cursor: pointer;
        //     }
        // }

    }

    .quiz-header {
        .close {
                cursor: pointer;
                color: #fff;
                width: 27px;
                height: 27px;
            }
    }
    


}