.HotSpotQuiz {
  position: relative;
  display: inline-block;

  > .background {
  }

  > .hotSpotAreas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .hotSpotArea {
      position: absolute;
      border: 1px solid rgba(255, 255, 255, 0.5);

      &:not(.-show-correctness) {
        display: none;
      }
      &.-show-correctness.-selected {
        background: rgba(68, 204, 68, 0.5);
      }
      &.-show-correctness:not(.-selected) {
        background: rgba(255, 68, 68, 0.5);
      }
    }
  }

  > .spots {

    > .spot {
      position: absolute;
      width: rem(15px);
      height: rem(15px);
      border-radius: 50%;
      transform: translate(-50%, -50%);

      border: 1px solid rgba(0, 0, 0, 0.5);
      &:not(.-show-correctness) {
        background: rgba(0, 0, 0, 0.5);
      }
      &.-show-correctness.-in-area {
        background: rgba(68, 204, 68, 0.5);
      }
      &.-show-correctness:not(.-in-area) {
        background: rgba(255, 68, 68, 0.5);
      }
    }
  }
}
