.point-navigation {
    

    >.point {
        
        &.-teacher {
            cursor: pointer;
        }
        
        margin: 5px;

        &.svg-icon {
            &.-current {
                width: 30px;
                height: 30px;
            }
        }
    }
}



@media all and ( max-width: theme.settings.breakpoints.small ) {
    .point-navigation {
        >.point {
            margin: 2px;
            &.svg-icon {
                width: 16px;
                height: 16px;
                &.-current {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}


