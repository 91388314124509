.story-solution {
    position: relative;
    padding-top:150px;
    
    ._hidden {
        display:none;
    }

    >.container {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.05);
        padding-top: 40px;
        
        // width: 80%;
        margin: 0px auto;;
        z-index: 5;
        position:relative;
        // transform: translate(0%, 50%);
    }
}