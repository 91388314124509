

    .quiz {
        z-index: 20;
        &.-open{
            display: block;
            position: absolute; 
            top:0px;
            left: 0px;
            width: 100vw;
            min-height: 100vh;
            background-color: theme.colors.main-purple;
            overflow-y: auto;
        }
        &.-close {
            display: none;
        }

        .inner-box {
            // max-height:70vh;
            padding:  32px 24px 32px 24px;
            overflow-y: auto;
             position:absolute;
            margin-top: 60px;
            // margin-left: 32px;
            // margin-right: 32px;
            margin-bottom: 60px;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 30px;

            background-color: #fff;
        }

        .quiz-header {
            z-index: 10;
            height: 60px;
            color: #fff;
            position: fixed;
            width: 100%;
            background-color: theme.colors.main-purple;
            
            .title {
                text-align: center;
            }
            .close {
                cursor: pointer;
            }
            .close {
                width: 27px;
                height: 27px;
            }
            
        }
        

}

