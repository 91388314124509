.audioplayer {
    text-align:right;
    line-height: 0;
    &.-playing {
        // >.svg-icon {
        //     color:red;  
        // }

        .audio-start {
            display: none;
        }
        .audio-pause {
            display: inline-block;
        }
        
    }

    &.-stopped {
        .audio-start {
            display: inline-block;
        }
        .audio-pause {
            display: none;
        }
    }
    >.svg-icon {
        width: rem(48px);
        height: rem(48px);
        color: theme.colors.main-purple;
        cursor:pointer;
        &.-hover {
            color:red;
        }
    }
}