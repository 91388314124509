.ClozeQuiz {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;

    
  .choice {
    &.-show-correctness.-correct.-selected {
      background: #4c4;
    }
    &.-show-correctness.-correct:not(.-selected) {
      background: #f44;
      color: #fff;
    }
    &.-show-correctness:not(.-correct).-selected {
      background: #f44;
      color: #fff;
    }
  }
}
