.info-modal {
    position: fixed;
    top: 0px;
    right:0px;
    bottom: 0px;
    overflow-y: auto;
    left: 0px;
    padding: 20px;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.8);
    >.inner {
        background-color: #fff;
        // max-width: 700px;
        // margin: 0 auto;

        .close-button {
            text-align: right;
            cursor: pointer;
            color: #000;
        }
        
        >.text {
            background-color: #fff;
        }
    }

    
}