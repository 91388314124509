.story-quiz {
    // padding-bottom: 100px;

    .quiz-button {
        position:relative;
        // margin-top: 10vh;
        text-align: center;
        z-index: 10;
        cursor: pointer;

        &.-unsolved {
            .quiz-open {
                display: inline-block;
            }
            .quiz-completed {
                display: none;
            }

        }
        &.-completed {
            .quiz-open {
                display: none;
            }
            .quiz-completed {
                display: inline-block;
            }

        }

        >.svg-icon {
            width: rem(100px);
            height: rem(100px);
        }

        .explanation-container {
            
            display:block;
            margin-top: 7px;
            .explanation {
                background-color: theme.colors.main-green;
                display: inline-block;
                border-radius: 6px;
                box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.05);
                width: 245px;
                padding: 8px 16px;
            }
            // opacity:1;
            // transition: opacity 1s ease-in-out;

            &._hidden {
                display: none;
                // opacity: 0;
                //  transition: opacity 1s ease-in-out;
            }
        }
        
    }
}


