.fullscreen-image {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
    width: 100vw;
    height: 100vh;
    
    padding: 20px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);

    >.image {
        max-width: 80%;
        margin: auto;
    }

    >.close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        cursor: pointer;
    }
    
}