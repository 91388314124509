

.cover-image {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        position: relative;
        z-index: 5;
        

    >.background-image {
        // height: 90vh;
        height: calc(100vh - 60px); //this surely doesnt work everywhere
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        background-color: theme.colors.background-light-pink;
    }

    .cloud-overlay {
        position: relative;
        top: -290px;

        .cloud {
            height: 220px;
            width:100%
            &.-mobile {
                display: none;
            }
        }
    }
}


@media all and ( max-width: theme.settings.breakpoints.small ) {
    
    .cover-image {
        .cloud-overlay {
            .cloud {
                &.-mobile {
                    display: block;
                }
                &.-desktop {
                    display: none;
                }
            }
        }
    }

}
