.quiz-button{

    .svg-icon {
        
        
        &.-animate {
          animation: pulse 2s infinite;
          border-radius: 50%;
          border: 1px solid theme.colors.main-purple;
          // width:100px;
          // height:100px;
          // margin: 0 auto;
          margin: 25px;
          background-color: theme.colors.main-purple;

              &:hover {
                  animation: none;
              }
        }
    }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(74, 37, 124, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 20px rgba(74, 37, 124, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(74, 37, 124, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(74, 37, 124, 0.4);
    box-shadow: 0 0 0 0 rgba(74, 37, 124, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 20px rgba(74, 37, 124, 0);
      box-shadow: 0 0 0 20px rgba(74, 37, 124, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(74, 37, 124, 0);
      box-shadow: 0 0 0 0 rgba(74, 37, 124, 0);
  }
}

}