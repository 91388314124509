.ChoiceQuiz {
    >.choices {
        >.choice {
            // display: block;

            display: flex;
            cursor: pointer;

            .text, .text > p {
                display: block;
                margin-left: 14px;
            }
        }
    }
}