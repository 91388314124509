

.story-page {
    // min-height:120vh;
    background-color: theme.colors.background-light-pink;
    position:relative;

    >.header {
        position: sticky;
        top: 0px;
        z-index: 100;
        height: 60px;
        width: 100%;
        cursor: pointer;
        background-color: theme.colors.main-purple;
        color: #fff;
        .svg-icon {
            color: #fff;
            width: 30px;
            height: 30px;
            vertical-align:sub;
        }

        &._hidden {
            display: none;
        }
    }

    .vertical-line {
         height:100%;
         border: 2px solid white;
         top:0;
         left: 50%;
         position:absolute;
    }

    .background-hack {
        display: none;
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 0px;
        background-color: theme.colors.background-light-pink;
        z-index: 5;
    }

    
    .chapter:last-child {
        padding-bottom: 100px;
        .background-hack {
            display: block;
        }
    }



    //hack....
    .chapter:nth-child(1), .chapter:nth-child(2) {
        &._hidden {
            display:block
        }
    }




}

