
/*!
---
name: DropDown
category: UI/DropDown
tag:
 - component
 - dropdown
compile: true
---

A dropdown list to select one option out of many in the list.

## Example
```jade
div(view='DropDown',items='[[ [ { "label":"option 1", "value":1 }, { "label":"option 2", "value":2 }, { "label":"option 3", "value":3 } ] ]]')
```
*/
.drop-down {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E')  
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    background-color: #fff;
    border: 1px solid black;
    width: 100%;
    cursor: pointer;

    .button, .list {
      padding: 5px 30px 5px 7px;
    }


 

    &:not( .-open ) {
        > .list {
            display: none;
        }
    }

    &.-float {
      position: relative;

      > .list {
        background-color: #fff;
        border-left:  1px solid black;
        border-right:  1px solid black;;
        border-bottom:  1px solid black;;
        left: -1px;
        right: -1px;
        position: absolute;
        z-index: 1000;

        &.-v-t-l {
          bottom: 100%;
        }

        &.-v-t-r {
          right: 0;
          bottom: 100%;
        }

        &.-h-t-r {
          right: 0;
          bottom: 100%;
          transform: translate( 100%, 100% );
        }

        &.-h-b-r {
          right: 0;
          bottom: 0;
          transform: translate(100%, 0%);
        }

        &.-v-b-r {
          right: 0;
        }

        &.-h-t-l {
          top: 0;
          transform: translate(-100%, 0);
        }

        &.-h-b-l {
          bottom: 0;
          transform: translate(-100%, 0);
        }
      }
    }
}
