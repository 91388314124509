/*
---
name: Colors
template: colors
tag:
 - variable
 - color
colors:
 - variable: "body"
   description: "The main background color"
   value: "#FFF"
 - variable: "text"
   description: "The default text color on the body"
   value: "#202023"
 - variable: "text-gray"
   description: "The default color for grayed-out text (e. g. for disabled items or subcaptions)"
   value: "#6A6A6A"
 - variable: "text-inverted"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "action"
   description: "The main action color used for links and buttons"
   value: "#3031FE"
 - variable: "line"
   description: "The default color for lines (e. g. border lines)"
   value: "#e0e0e0"
 - variable: "success"
   description: "The color for positive feedback (e. g. confirmation messages)"
   value: "#00A685"
 - variable: "error"
   description: "The color for negative feedback (e. g. error messages)"
   value: "#DC153A"
---

Color variables that are used throughout the library are defined in the `theme.colors` hash.
By default, the following keys are defined:
*/
/*
---
name: rem
category: Functions/rem
tag:
 - function
---

Converts from `px` to `rem`, based on the assumption that `16px` are equivalent to `1rem`.

## Definition:

```stylus
rems = rem(pixels);
```
*/
/*
---
name: chooseMaxContrast
category: Functions/chooseMaxContrast
tag:
 - color
 - function
---

Given one or more foreground colors `fgs` and one background color `bg`,
returns the foreground color with the highest contrast ratio with respect to `bg`.

## Definition:

```stylus
fg = chooseMaxContrast(fgs, bg);
```

## Example:

```stylus
bg = theme.colors.footer;
fg = theme.colors.text;
fgInverted = theme.colors.text-inverted;

theme.components.color-box.footer = {
  background-color: (bg),
  color: (chooseMaxContrast((fg fgInverted), bg)),
}
```
*/
/*
---
name: Global settings
template: variables
tag:
 - variable
variables:
 - name: "text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "text-font"
   description: "The `font-family` to be used for regular text"
   default: "'Roboto', serif"
 - name: "heading-font"
   description: "The `font-family` to be used for headings (and possibly subcaptions)"
   default: "'Roboto', serif"
 - name: "text-rendering"
   description: "The `text-rendering` of the body"
   default: "optimizeLegibility"
 - name: "transition-duration"
   description: "The duration of the slide transitions"
   default: "0.3s"
 - name: "border-radius"
   description: "The default radius for borders"
   default: "2px"
---

The `theme.settings` hash defines several global settings for text formatting and styling:
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
 - name: "border-radius"
   description: "The default radius for borders"
   default: "2px"
---

These variables setups some global text formatting and styling.
*/
/*
---
name: Global Reset
category: Globals/Reset
tag:
 - reset
---

The lernetz css library has a set of reset style to have the same behaviour on every browser.
Furthermore this reset sets some global variables like:
 * body font-families
 * body line-height
 * body text-rendering
 * :root font-size for proper rem conversion
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  font-weight: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: 1.5rem;
  color: ;
  font-family: 'Helvetica' , sans-serif;
  text-rendering: optimizeLegibility;
  background-color: ;
  -webkit-font-smoothing: antialiased;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  display: block;
}
ul,
ol {
  margin-left: 1.25rem;
}
/*
---
name: Global helpers
category: Globals/Helpers
tag:
 - helper
 - box-model
 - display
 - position
 - visibility
compile: true
---

A collection of global helpers to control common styles.
Note that every global helper is perfixed with an underscore to indicate that this is a global rule according to rscss.

## Z-Index:

```jade
div._z-1 z-index: 1;
div._z-2 z-index: 2;
div._z-3 z-index: 3;
div._z-4 z-index: 4;
div._z-5 z-index: 5;
div._z-6 z-index: 6;
```

## Position:

```jade
div._absolute position: absolute;
div._relative position: relativ;
div._fixed position: fixed;
div._sticky position: sticky;
div._static position: static;
```

## Display:

```jade
div._block display: block;
div._inline-block display: inline-block;
div._inline display: inline;
div._flex display: flex;
div._inline-flex display: inline-flex;
div._table display: table;
div._table-row display: table-row;
div._table-cell display: table-cell;
div._hidden display: none;
```

## Visibility:

```jade
div._visible visibility: visible;
div._invisible visibility: hidden;
```
*/
._z-1 {
  z-index: 1;
}
._z-2 {
  z-index: 2;
}
._z-3 {
  z-index: 3;
}
._z-4 {
  z-index: 4;
}
._z-5 {
  z-index: 5;
}
._z-6 {
  z-index: 6;
}
._absolute {
  position: absolute;
}
._relative {
  position: relative;
}
._fixed {
  position: fixed;
}
._sticky {
  position: sticky;
}
._static {
  position: static;
}
._block {
  display: block;
}
._inline-block {
  display: inline-block;
}
._inline {
  display: inline;
}
._flex {
  display: flex;
}
._inline-flex {
  display: inline-flex;
}
._table {
  display: table;
}
._table-row {
  display: table-row;
}
._table-cell {
  display: table-cell;
}
._hidden {
  display: none;
}
._visible {
  visibility: visible;
}
._invisible {
  visibility: hidden;
}
/*
---
name: .abs-layout
category: Components/.abs-layout
tag:
 - component
 - layout
compile: true
---

A simple component to position an element absolute. Default is top, left

## Variants

The variants for the vertical positioning are `.-top`, `.-vcenter` and `.-bottom`.
The variants for the horizontal positioning are `.-right`, `.-hcenter` and `.-left`.
Additinally the `-center` variant will center horizontal and vertical.
Variants to stretch an item to its full width/height can be dine with `.item.-stretch`.
In some situations the abs-layout itself needs to stretch so use the stretch variant on the component. `.abs-layout.-stretch` 

```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-center
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-right
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-bottom
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-left
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-top
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-stretch
```

## Z-Index

Every item can controll its z index with the `.-z{i}`variants.
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-left.-z1
    div.aigis-debug.item.-top.-left.-z2
```

*/
.abs-layout {
  position: relative;
}
.abs-layout.-stretch {
  width: 100%;
  height: 100%;
}
.abs-layout > .item {
  position: absolute;
}
.abs-layout > .item.-top {
  top: 0;
}
.abs-layout > .item.-right {
  right: 0;
}
.abs-layout > .item.-bottom {
  bottom: 0;
}
.abs-layout > .item.-left {
  left: 0;
}
.abs-layout > .item.-vcenter {
  top: 50%;
  transform: translateY(-50%);
}
.abs-layout > .item.-hcenter {
  left: 50%;
  transform: translateX(-50%);
}
.abs-layout > .item.-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.abs-layout > .item.-stretch {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.abs-layout > .item.-z1 {
  z-index: 1;
}
.abs-layout > .item.-z2 {
  z-index: 2;
}
.abs-layout > .item.-z3 {
  z-index: 3;
}
.abs-layout > .item.-z4 {
  z-index: 4;
}
.abs-layout > .item.-z5 {
  z-index: 5;
}
.abs-layout > .item.-z6 {
  z-index: 6;
}
.abs-layout > .item.-z7 {
  z-index: 7;
}
.abs-layout > .item.-z8 {
  z-index: 8;
}
.abs-layout > .item.-z9 {
  z-index: 9;
}
.abs-layout > .item.-z10 {
  z-index: 10;
}
/*
---
name: .border-box
category: Components/.border-box
tag:
 - component
 - border
compile: true
---

A box to control borders on all four sides.

```jade
div.border-box.-left Some demo content
```

```jade
div.border-box.-all Some demo content
```
*/
.border-box.-top {
  border-top-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-right {
  border-right-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-bottom {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-left {
  border-left-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-all {
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-round {
  border-radius: 50%;
}
/*
---
name: .button-style
category: Components/.button-style
tag:
 - component
 - button
compile: true
---

A button component as a base for different button variants defined in its configuration.

```jade
button.button-style default
```

```jade
button.button-style.-disabled -disabled
```

```jade
button.button-style.-hover -hover
```

```jade
button.button-style.-main -main
```

```jade
button.button-style.-main.-hover -main -hover
```
*/
.button-style {
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.button-style.-main {
  color: #fff;
  background-color: #3031fe;
  padding: 0.5rem 1rem;
}
.button-style.-main.-hover:hover {
  color: #fff;
  background-color: #1616bd;
}
.button-style.-hover {
  transition: all 0.1s;
}
.button-style.-hover:hover {
  color: #3031fe;
}
.button-style.-disabled {
  cursor: inherit;
  background-color: #b6b6b6;
}
/*
---
name: .color-box
category: Components/.color-box
tag:
 - component
 - color
compile: true
---

Controls the background and/or foreground color(s) of the styled element.

## Configuration

This component produces a variant for each key in the `theme.components.color-box` hash.
For example, you can therefore a `-footer` variants by including the following in the file `color-box.var.styl`:

```stylus
theme.components.color-box.footer = {
  background-color: theme.colors.footer,  // assuming this non-default key is defined elsewhere
  color: theme.colors.text,
};
```

## Default variants:

By default, the following `color-box` variants are defined via `theme.components.color-box`:

```jade
div(style="display:flex")
  div.color-box.-body(style="width:100px;height:100px;margin:5px;padding:5px")
    span -body
  div.color-box.-action(style="width:100px;height:100px;margin:5px;padding:5px")
    span -action
  div.color-box.-black(style="width:100px;height:100px;margin:5px;padding:5px")
    span -black
  div.color-box.-white(style="width:100px;height:100px;margin:5px;padding:5px")
    span -white
```

## See also:

* [chooseMaxContrast](../../Shared/Functions/index.html#chooseMaxContrast) &mdash;
  In order to guarantee good legibility, the foreground `color` should be chosen
  in relation to the `background-color` such that a reasonably high contrast ratio
  will result. If you are working with `theme.colors` variables instead of fixed
  color values, this can help you select the best one; see there for an example.
*/
.color-box.-body {
  background-color: #fff;
  color: #202023;
}
.color-box.-action {
  background-color: #3031fe;
  color: #fff;
}
.color-box.-white {
  background-color: #fff;
  color: #000;
}
.color-box.-black {
  background-color: #000;
  color: #fff;
}
/*
---
name: .display-responsive
category: Components/.display-responsive
tag:
 - responsive
 - display
compile: true
---

Controls the display of an element depending on the current breakpoint

## Hide

`.-hide-medium` hides an element as long as the screen is wider than the breakpoint `medium`.

```jade
div.display-responsive.-hide-medium Only shown when the screen is wider than the breakpoint medium
```

## Show

`.-show-medium` show an element as long as the screen is narrower than the breakpoint `medium`.

```jade
div.display-responsive.-show-medium Only shown when the screen is narrower than the breakpoint `medium`
```

*/
@media all and (max-width: 31.25rem) {
  .display-responsive.-hide-xsmall {
    display: none;
  }
}
@media all and (min-width: 31.25rem) {
  .display-responsive.-show-xsmall {
    display: none;
  }
}
@media all and (max-width: 44.125rem) {
  .display-responsive.-hide-small {
    display: none;
  }
}
@media all and (min-width: 44.125rem) {
  .display-responsive.-show-small {
    display: none;
  }
}
@media all and (max-width: 62rem) {
  .display-responsive.-hide-medium {
    display: none;
  }
}
@media all and (min-width: 62rem) {
  .display-responsive.-show-medium {
    display: none;
  }
}
@media all and (max-width: 75rem) {
  .display-responsive.-hide-large {
    display: none;
  }
}
@media all and (min-width: 75rem) {
  .display-responsive.-show-large {
    display: none;
  }
}
/*
---
name: .fixed-layout
category: Components/.fixed-layout
tag:
 - container
 - layout
 - fixed
---

Fixed container
You can position the container with the different variants `.-top`, `.-bottom`, `.-left`, `.-right`.
To span full width / height use `.-left` & `.-right` / `.-top` & `.-bottom`

Fixed top and full width

```jade
div.fixed-layout.-top.-left.-right.aigis-debug top left fullwidth
    
```

Fixed right and full height

```jade
div.fixed-layout.-top.-bottom.-right.aigis-debug top right fullheight
    
```

*/
.fixed-layout {
  position: fixed;
}
.fixed-layout.-top {
  top: 0;
}
.fixed-layout.-bottom {
  bottom: 0;
}
.fixed-layout.-left {
  left: 0;
}
.fixed-layout.-right {
  right: 0;
}
/*
---
name: .fixed-page-layout
category: Components/.fixed-page-layout
tag:
 - component
 - layout
 - fixed
 - page
compile: true
---

A page layout with a fixed header and optional sidebars.
With the `-margin-{top|left|right}`, `-margin-{top|left|right}-small` variants you can control the spacing.
If the content for example needs a spacing towards the header use: `.content.-margin-top`.
If the sidebar show be below the header use: `.sidebar.-left.-margin-top`.


There are variants to resize the header and sidebars to a smaller width/height: `.header.-small`, `.sidebar.-small`.
If you need a spacing on your content or sidebar you need to adjust that manually like this:
```
div.fixed-page-layout
	div.header.-small
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top-small Content
	div.sidebar.-left.-margin-top-small.-small
		div.aigis-debug(style="height:100%")  Left Sidebar
```

## Header with sidebars

```jade
div.fixed-page-layout
	div.header
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top Content
	div.sidebar.-left.-margin-top
		div.aigis-debug(style="height:100%")  Left Sidebar
	div.sidebar.-right
		div.aigis-debug(style="height:100%")  Right Sidebar
```
*/
.fixed-page-layout > .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3.125rem;
}
.fixed-page-layout > .header.-small {
  height: 1.875rem;
}
.fixed-page-layout > .header.-hide {
  transform: translateY(-100%);
}
.fixed-page-layout > .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 14.5625rem;
}
.fixed-page-layout > .sidebar.-small {
  width: 3.125rem;
}
.fixed-page-layout > .sidebar.-margin-top {
  top: 3.125rem;
}
.fixed-page-layout > .sidebar.-margin-top-small {
  top: 1.875rem;
}
.fixed-page-layout > .sidebar.-left {
  left: 0;
}
.fixed-page-layout > .sidebar.-left.-hide {
  transform: translateX(-100%);
}
.fixed-page-layout > .sidebar.-right {
  right: 0;
}
.fixed-page-layout > .sidebar.-right.-hide {
  transform: translateX(100%);
}
.fixed-page-layout > .content.-margin-right {
  margin-right: 14.5625rem;
}
.fixed-page-layout > .content.-margin-left {
  margin-left: 14.5625rem;
}
.fixed-page-layout > .content.-margin-right-small {
  margin-right: 3.125rem;
}
.fixed-page-layout > .content.-margin-left-small {
  margin-left: 3.125rem;
}
.fixed-page-layout > .content.-margin-top {
  margin-top: 3.125rem;
}
.fixed-page-layout > .content.-margin-top-small {
  margin-top: 1.875rem;
}
/*
---
name: .flex-layout
category: Components/.flex-layout
tag:
 - component
 - layout
 - flexbox
compile: true
---

A layout system based on flexbox to align and distribute items horizontally and vertically.


# Alignment of items

The `-center` shortcut will center all items horizontal & vertical
```jade
div(style="height: 100px").flex-layout.-center
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```

`-baseline` will align items based on their baseline. Works good if all the items contain text. 
```jade
div.flex-layout.-baseline
	div.aigis-debug Item
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
```

`-items-top` & `-items-bottom` will align the items either on top or bottom
```jade
div.flex-layout.-items-top
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-items-bottom
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

`-items-center` will center the items vertically
```jade
div.flex-layout.-items-center
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

# Alignment of individual items

```jade
div(style="height: 100px").flex-layout
	div.aigis-debug.item.-top top
	div.aigis-debug.item.-center center
	div.aigis-debug.item.-bottom bottom
	div.aigis-debug.item.-stretch stretch
```

# Row mode

If you like a container that spans the full width with item that resize themself you can use the `-row` variant.

```jade
div.flex-layout.-row
	div.aigis-debug.item Item 1
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

If you like to fix an item based on its content width use the `-content` variant.
This is a shortcut for `-no-grow -no-shrink`. 
```jade
div.flex-layout.-row
	div.aigis-debug.item.-content
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

You can control the sizing of the items with `-grow`, `-shrink`, `-no-grow`, `-no-shrink`.
```jade
div.flex-layout.-row
	div.aigis-debug.item.-no-shrink
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item.-no-grow Item 2
	div.aigis-debug.item Item 3
```


# Distribution of items

Use `-[v/h]-space-around` & `-[v/h]-space-between` to distribute the items in the flex container.

```jade
div.flex-layout.-h-space-around
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-h-space-between
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div(style="height: 200px").flex-layout.-v-space-around.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-v-space-between.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```

# Alignment of multiline items with wrap

If you have alot on items that wrap you can align the multilines with the following variants:
```jade
div.flex-layout.-wrap.-h-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-left
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-right
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-v-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-top
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-bottom
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```




*/
.flex-layout {
  display: flex;
}
.flex-layout.-inline {
  display: flex-inline;
}
.flex-layout.-wrap {
  flex-wrap: wrap;
}
.flex-layout.-row {
  flex-wrap: nowrap;
}
.flex-layout.-row > .item {
  flex: 1 1 auto;
}
.flex-layout.-column {
  flex-direction: column;
}
.flex-layout.-center {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.flex-layout.-baseline {
  align-items: baseline;
}
.flex-layout.-left {
  justify-content: flex-start;
}
.flex-layout.-right {
  justify-content: flex-end;
}
.flex-layout.-top {
  align-content: flex-start;
}
.flex-layout.-bottom {
  align-content: flex-end;
}
.flex-layout.-v-center {
  align-content: center;
}
.flex-layout.-v-space-between {
  align-content: space-between;
}
.flex-layout.-v-space-around {
  align-content: space-around;
}
.flex-layout.-h-center {
  justify-content: center;
}
.flex-layout.-h-space-between {
  justify-content: space-between;
}
.flex-layout.-h-space-around {
  justify-content: space-around;
}
.flex-layout.-items-top {
  align-items: flex-start;
}
.flex-layout.-items-bottom {
  align-items: flex-end;
}
.flex-layout.-items-center {
  align-items: center;
}
.flex-layout.-items-stretch {
  align-items: flex-stretch;
}
.flex-layout > .item.-stretch {
  align-self: stretch;
}
.flex-layout > .item.-top {
  align-self: flex-start;
}
.flex-layout > .item.-center {
  align-self: center;
}
.flex-layout > .item.-bottom {
  align-self: flex-end;
}
.flex-layout > .item.-content {
  flex: none;
}
.flex-layout > .item.-grow {
  flex-grow: 1;
}
.flex-layout > .item.-shrink {
  flex-shrink: 1;
}
.flex-layout > .item.-no-grow {
  flex-grow: 0;
}
.flex-layout > .item.-no-shrink {
  flex-shrink: 0;
}
/*
---
name: .grid-layout
category: Components/.grid-layout
tag:
 - component
 - layout
 - grid
 - responsive
 - flexbox
compile: true
---

A grid layout system based on flexbox. It provides sizing variants to size on different percentages.


## Percentage based sizing

The easiest use case is to use simple `.item -w1`, `.item -w1-2`, `.item -w1-3`, `.item -w1-4` for a percentage based side-by-side rendering.

```jade
div.grid-layout
	div.item.-w1
		div.aigis-debug item -w1
	div.item.-w1-2 
		div.aigis-debug item -w1-2
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-3
		div.aigis-debug item -w1-3
	div.item.-w2-3
		div.aigis-debug item -w2-3
	div.item.-w1-4
		div.aigis-debug item -w1-4
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-4
		div.aigis-debug item -w1-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the global settings `theme.settings.breakpoints` and can be overwritten in `theme.components.grid-layout.breakpoints`.
The default settings define breakpoints with labels: `.-small-X-X`, `.-medium-X-X`, `.-large-X-X`.

```jade
div.grid-layout
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1 -medium-1-2 -large-1-4
```


## Gutter

If you need a spacing between the columns add the `-gutter` variant.
The size of the gutter is specified in the `grid-gutter`variable.

```jade
div.grid-layout.-gutter
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-2
		div.aigis-debug col -w1-2
```


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your table-grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid-layout.-reverse
	div.item.-w1-4
		div.aigis-debug First column in DOM
	div.item.-w1-4
		div.aigis-debug Second column in DOM
	div.item.-w1-4
		div.aigis-debug Third column in DOM
	div.item.-w1-4
		div.aigis-debug Fourth column in DOM
```

## Vertical centering

You can vertical center with the help of the `flex-layout` component.

```jade
div.grid-layout.flex-layout
	div.item.-w1-4
		div.aigis-debug A column with a lot of content that should wrap on fixed size.
	div.item.-bottom
		div.aigis-debug bottom
	div.item.-center
		div.aigis-debug center
	div.item.-stretch
		div.aigis-debug(style="height:100%") stretch
```

*/
.grid-layout {
  display: flex;
  flex-wrap: wrap;
}
.grid-layout.-gutter {
  margin: -0.625rem -0.9375rem;
}
.grid-layout.-gutter > .item {
  padding: 0.625rem 0.9375rem;
}
.grid-layout.-reverse {
  flex-direction: row-reverse;
}
.grid-layout > .item.-w1 {
  flex: 0 0 100%;
  max-width: 100%;
}
.grid-layout > .item.-w1-2 {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid-layout > .item.-w1-3 {
  flex: 0 0 33.333333333333336%;
  max-width: 33.333333333333336%;
}
.grid-layout > .item.-w2-3 {
  flex: 0 0 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.grid-layout > .item.-w1-4 {
  flex: 0 0 25%;
  max-width: 25%;
}
.grid-layout > .item.-w3-4 {
  flex: 0 0 75%;
  max-width: 75%;
}
@media all and (min-width: 31.25rem) {
  .grid-layout > .item.-xsmall-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-xsmall-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-xsmall-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-xsmall-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-xsmall-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-xsmall-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 44.125rem) {
  .grid-layout > .item.-small-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-small-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-small-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-small-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-small-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-small-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 62rem) {
  .grid-layout > .item.-medium-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-medium-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-medium-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-medium-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-medium-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-medium-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 75rem) {
  .grid-layout > .item.-large-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-large-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-large-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-large-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-large-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-large-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
/*
---
name: .image-responsive
category: Components/.image-responsive
tag:
 - component
 - responsive
 - image
 - media
compile: true
---

This class makes the image scaling to its max width.

```jade
img.image-responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```

## Variants

`.-full` forces the picture to span to 100% of the parent

```jade
img.image-responsive.-full(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
.image-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}
.image-responsive.-full {
  width: 100%;
}
.image-responsive.-inset {
  max-height: 100%;
}
/*
---
name: .max-width-layout
category: Components/.max-width-layout
tag:
 - component
 - layout
 - responsive
compile: true
---

This component will limit its content to a maximum width. Forthermore it can center its content.
Usefull for page content that should not span the full width.


## Percentage based sizing

*/
@media all and (min-width: 44.125rem) {
  .max-width-layout {
    max-width: 41.75rem;
  }
}
@media all and (min-width: 62rem) {
  .max-width-layout {
    max-width: 55.75rem;
  }
}
@media all and (min-width: 75rem) {
  .max-width-layout {
    max-width: 68.75rem;
  }
}
.max-width-layout.-center {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .media-responsive
category: Components/.media-responsive
tag:
 - responsive
 - media
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.media` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

```jade
div.media-responsive.-media-4-3
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

*/
.media-responsive {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.media-responsive.-media-1-1 {
  padding-bottom: 50%;
}
.media-responsive.-media-4-3 {
  padding-bottom: 75%;
}
.media-responsive.-media-16-9 {
  padding-bottom: 56.25%;
}
.media-responsive > .media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
---
name: .overflow-text
category: Components/.overflow-text
tag:
 - component
 - text
compile: true
---

This component cuts the text on a given height and adds a fadeout effect.
This is usefull for text containers that have more content than space.


```jade
div.overflow-text(style="width: 200px;height: 70px") This is a very long text that is rwaped and cut at the end.
```
*/
.overflow-text {
  overflow: hidden;
  position: relative;
}
.overflow-text:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2.1875rem;
  background: linear-gradient(to bottom, rgba(255,255,255,0), #fff 70%);
}
/*
---
name: .shadow-box
category: Components/.shadow-box
tag:
 - component
 - shadow
compile: true
---

A box to control box-shadows with different variants

## Default variants
```jade
div.shadow-box Demo content
div.shadow-box.-inset Demo content
div.shadow-box.-zero Use zero shadow in animations
```
*/
.shadow-box {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-inset {
  box-shadow: inset 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-zero {
  box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.17);
}
/*
---
name: .spacer-box
category: Components/.spacer-box
tag:
 - component
 - layout
 - box-model
compile: true
---

This component is used to control spacing of a box. It mainly sets the margin and padding with a fixed set of configuration values.
The default space sizes are:
```
xxs: 2
xs: 4
s: 8
default: 16
l: 32
xl: 64`
```


To control the padding/margin for a specific space you can use the following variants:
```
-{kind}-{side}-{space}
-{kind}-{axis}-{space}
-{kind}-{space}

kind: m | p
side: top | right | bottom | left
space: xxs | xs | s | l | xl
axis: v | h
```

Or to use the `default` padding space you can use the following shortcuts:
```
-{kind}-{side}
-{kind}-{axis}
-{kind}

kind: m | p
side: top | right | bottom | left
axis: v | h
```

## Examples

Here are some example that show the usage with specific space sizes.
```jade
div.spacer-box.-m-s
	div.aigis-debug Apply s margin on all sides
```
```jade
div.spacer-box.-p-v-xl
	div.aigis-debug Apply xl padding on vertical sides
```
```jade
div.spacer-box.-p-top-l
	div.aigis-debug Apply l padding on top side
```

Here are some examples that show the usage with default space size.

```jade
div.spacer-box.-p
	div.aigis-debug Apply default padding on all sides
```
```jade
div.spacer-box.-m-h
	div.aigis-debug Apply default margin on horizontal axis
```
```jade
div.spacer-box.-m-bottom
	div.aigis-debug Apply default margin on bottom side
```

## Auto margin

For setting the vertical margin to auto the `.-auto` variant can be used.

```jade
div.spacer-box.-auto(style="width: 200px")
	div.aigis-debug Apply margin auto to left and right
```

*/
.spacer-box.-m-top-xxs {
  margin-top: 0.125rem;
}
.spacer-box.-p-top-xxs {
  padding-top: 0.125rem;
}
.spacer-box.-m-right-xxs {
  margin-right: 0.125rem;
}
.spacer-box.-p-right-xxs {
  padding-right: 0.125rem;
}
.spacer-box.-m-bottom-xxs {
  margin-bottom: 0.125rem;
}
.spacer-box.-p-bottom-xxs {
  padding-bottom: 0.125rem;
}
.spacer-box.-m-left-xxs {
  margin-left: 0.125rem;
}
.spacer-box.-p-left-xxs {
  padding-left: 0.125rem;
}
.spacer-box.-m-h-xxs {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.spacer-box.-m-v-xxs {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.spacer-box.-m-xxs {
  margin: 0.125rem 0.125rem;
}
.spacer-box.-p-h-xxs {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.spacer-box.-p-v-xxs {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.spacer-box.-p-xxs {
  padding: 0.125rem 0.125rem;
}
.spacer-box.-m-top-xs {
  margin-top: 0.25rem;
}
.spacer-box.-p-top-xs {
  padding-top: 0.25rem;
}
.spacer-box.-m-right-xs {
  margin-right: 0.25rem;
}
.spacer-box.-p-right-xs {
  padding-right: 0.25rem;
}
.spacer-box.-m-bottom-xs {
  margin-bottom: 0.25rem;
}
.spacer-box.-p-bottom-xs {
  padding-bottom: 0.25rem;
}
.spacer-box.-m-left-xs {
  margin-left: 0.25rem;
}
.spacer-box.-p-left-xs {
  padding-left: 0.25rem;
}
.spacer-box.-m-h-xs {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.spacer-box.-m-v-xs {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.spacer-box.-m-xs {
  margin: 0.25rem 0.25rem;
}
.spacer-box.-p-h-xs {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.spacer-box.-p-v-xs {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.spacer-box.-p-xs {
  padding: 0.25rem 0.25rem;
}
.spacer-box.-m-top-s {
  margin-top: 0.5rem;
}
.spacer-box.-p-top-s {
  padding-top: 0.5rem;
}
.spacer-box.-m-right-s {
  margin-right: 0.5rem;
}
.spacer-box.-p-right-s {
  padding-right: 0.5rem;
}
.spacer-box.-m-bottom-s {
  margin-bottom: 0.5rem;
}
.spacer-box.-p-bottom-s {
  padding-bottom: 0.5rem;
}
.spacer-box.-m-left-s {
  margin-left: 0.5rem;
}
.spacer-box.-p-left-s {
  padding-left: 0.5rem;
}
.spacer-box.-m-h-s {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.spacer-box.-m-v-s {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.spacer-box.-m-s {
  margin: 0.5rem 0.5rem;
}
.spacer-box.-p-h-s {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.spacer-box.-p-v-s {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.spacer-box.-p-s {
  padding: 0.5rem 0.5rem;
}
.spacer-box.-m-top {
  margin-top: 1rem;
}
.spacer-box.-p-top {
  padding-top: 1rem;
}
.spacer-box.-m-right {
  margin-right: 1rem;
}
.spacer-box.-p-right {
  padding-right: 1rem;
}
.spacer-box.-m-bottom {
  margin-bottom: 1rem;
}
.spacer-box.-p-bottom {
  padding-bottom: 1rem;
}
.spacer-box.-m-left {
  margin-left: 1rem;
}
.spacer-box.-p-left {
  padding-left: 1rem;
}
.spacer-box.-m-h {
  margin-left: 1rem;
  margin-right: 1rem;
}
.spacer-box.-m-v {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.spacer-box.-m {
  margin: 1rem 1rem;
}
.spacer-box.-p-h {
  padding-left: 1rem;
  padding-right: 1rem;
}
.spacer-box.-p-v {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.spacer-box.-p {
  padding: 1rem 1rem;
}
.spacer-box.-m-top-l {
  margin-top: 1.5rem;
}
.spacer-box.-p-top-l {
  padding-top: 1.5rem;
}
.spacer-box.-m-right-l {
  margin-right: 1.5rem;
}
.spacer-box.-p-right-l {
  padding-right: 1.5rem;
}
.spacer-box.-m-bottom-l {
  margin-bottom: 1.5rem;
}
.spacer-box.-p-bottom-l {
  padding-bottom: 1.5rem;
}
.spacer-box.-m-left-l {
  margin-left: 1.5rem;
}
.spacer-box.-p-left-l {
  padding-left: 1.5rem;
}
.spacer-box.-m-h-l {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.spacer-box.-m-v-l {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.spacer-box.-m-l {
  margin: 1.5rem 1.5rem;
}
.spacer-box.-p-h-l {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.spacer-box.-p-v-l {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.spacer-box.-p-l {
  padding: 1.5rem 1.5rem;
}
.spacer-box.-m-top-xl {
  margin-top: 2rem;
}
.spacer-box.-p-top-xl {
  padding-top: 2rem;
}
.spacer-box.-m-right-xl {
  margin-right: 2rem;
}
.spacer-box.-p-right-xl {
  padding-right: 2rem;
}
.spacer-box.-m-bottom-xl {
  margin-bottom: 2rem;
}
.spacer-box.-p-bottom-xl {
  padding-bottom: 2rem;
}
.spacer-box.-m-left-xl {
  margin-left: 2rem;
}
.spacer-box.-p-left-xl {
  padding-left: 2rem;
}
.spacer-box.-m-h-xl {
  margin-left: 2rem;
  margin-right: 2rem;
}
.spacer-box.-m-v-xl {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.spacer-box.-m-xl {
  margin: 2rem 2rem;
}
.spacer-box.-p-h-xl {
  padding-left: 2rem;
  padding-right: 2rem;
}
.spacer-box.-p-v-xl {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.spacer-box.-p-xl {
  padding: 2rem 2rem;
}
.spacer-box.-m-top-xxl {
  margin-top: 4rem;
}
.spacer-box.-p-top-xxl {
  padding-top: 4rem;
}
.spacer-box.-m-right-xxl {
  margin-right: 4rem;
}
.spacer-box.-p-right-xxl {
  padding-right: 4rem;
}
.spacer-box.-m-bottom-xxl {
  margin-bottom: 4rem;
}
.spacer-box.-p-bottom-xxl {
  padding-bottom: 4rem;
}
.spacer-box.-m-left-xxl {
  margin-left: 4rem;
}
.spacer-box.-p-left-xxl {
  padding-left: 4rem;
}
.spacer-box.-m-h-xxl {
  margin-left: 4rem;
  margin-right: 4rem;
}
.spacer-box.-m-v-xxl {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.spacer-box.-m-xxl {
  margin: 4rem 4rem;
}
.spacer-box.-p-h-xxl {
  padding-left: 4rem;
  padding-right: 4rem;
}
.spacer-box.-p-v-xxl {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.spacer-box.-p-xxl {
  padding: 4rem 4rem;
}
.spacer-box.-auto {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .svg-icon
category: Components/.svg-icon
template: icons
tag:
 - component
 - symbol
 - icon
 - media
 - svg
compile: true
---

Defines common SVG icon/symbol sizes and presentation styles.

## Default

The <span class="variable">-default</span> variant applies automatically (but can be specified explicitly if desired).

```jade
div(style="display: inline-block; border: solid 1px #ccc;")
  svg.svg-icon
    use(xlink:href="#stars-icon")

div(style="display: inline-block; border: solid 1px #ccc;")
  svg.svg-icon.-default
    use(xlink:href="#stars-icon")
```

## -scale

The <span class="variable">-span</span> variant will automatically fit (scale & center) the SVG icon into its parent container.

```jade
div(style="display:inline-block; border: solid 1px #ccc; width:50px; height:100px")
  svg.svg-icon.-scale
    use(xlink:href="#stars-icon")

div(style="display:inline-block; border: solid 1px #ccc; width:200px; height:100px")
  svg.svg-icon.-scale
    use(xlink:href="#stars-icon")
```


*/
.svg-icon {
  fill: currentColor;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
.svg-icon.-inline {
  display: inline-block;
}
.svg-icon.-scale {
  width: 100%;
  height: 100%;
}
/*
---
name: .text-style
category: Components/.text-style
tag:
 - component
 - text
compile: true
---

A text styling component for different text styles

## Default heading

```jade
div.text-style Default heading
```

## H1
```jade
div.text-style.-h1 The h1 header

```

## H2
```jade
div.text-style.-h2 The h2 header
```

## H3
```jade
div.text-style.-h3 The h3 header
```

## Bold
```jade
div.text-style.-bold Bold text
```

## Align center
```jade
div.text-style.-center Centered text
```

## Align right
```jade
div.text-style.-right Right aligned text
```

*/
.text-style.-h1 {
  font-family: 'Helvetica' , sans-serif;
  font-size: 2rem;
  line-height: 3rem;
}
.text-style.-h2 {
  font-family: 'Helvetica' , sans-serif;
  font-size: 1.625rem;
  line-height: 1.5rem;
}
.text-style.-p {
  font-family: 'Helvetica' , sans-serif;
  font-size: 1.25rem;
  line-height: 2rem;
}
/*
---
name: .transform-box
category: Components/.transform-box
tag:
 - component
 - transform
 - box-model
compile: true
---

A box to control common transformations

## Rotate
```jade
div.transform-box.-rot90 Some demo content
div.transform-box.-rot180 Some demo content
div.transform-box.-rot270 Some demo content
```

## Flip
```jade
div.transform-box.-flipX Some demo content
div.transform-box.-flipY Some demo content
```
*/
.transform-box.-rot90 {
  transform: rotate(90deg);
}
.transform-box.-rot180 {
  transform: rotate(180deg);
}
.transform-box.-rot270 {
  transform: rotate(270deg);
}
.transform-box.-flipX {
  transform: scaleX(-1);
}
.transform-box.-flipY {
  transform: scaleY(-1);
}
/*
---
name: .transition-box
category: Components/.transition-box
tag:
 - component
 - transition
 - box-model
compile: true
---

A box to control transition on divs

## Enable all transitions
```jade
div.transition-box.-all All transitions enabled
```

## Control the speed of the transition
```jade
div.transition-box.-all.-slow Apply the slow duration variant
div.transition-box.-all.-fast Apply the fast duration variant
```
*/
.transition-box {
  transition-duration: 0.3s;
  transition-property: all;
}
.transition-box.-slow {
  transition-duration: 0.6s;
}
.transition-box.-fast {
  transition-duration: 0.15s;
}
.ClozeQuiz .choice.-show-correctness.-correct.-selected {
  background: #4c4;
}
.ClozeQuiz .choice.-show-correctness.-correct:not(.-selected) {
  background: #f44;
  color: #fff;
}
.ClozeQuiz .choice.-show-correctness:not(.-correct).-selected {
  background: #f44;
  color: #fff;
}
.ClozeQuiz .SelectSpan .choices.-show-correctness.-correct {
  border-color: #4c4;
}
.ClozeQuiz .SelectSpan .choices.-show-correctness:not(.-correct) {
  border-color: #f44;
}
.HotSpotQuiz {
  position: relative;
  display: inline-block;
}
.HotSpotQuiz > .hotSpotAreas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea {
  position: absolute;
  border: 1px solid rgba(255,255,255,0.5);
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea:not(.-show-correctness) {
  display: none;
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea.-show-correctness.-selected {
  background: rgba(68,204,68,0.5);
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea.-show-correctness:not(.-selected) {
  background: rgba(255,68,68,0.5);
}
.HotSpotQuiz > .spots > .spot {
  position: absolute;
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255,255,255,0.5);
}
.HotSpotQuiz > .spots > .spot:not(.-show-correctness) {
  background: rgba(255,255,255,0.2);
}
.HotSpotQuiz > .spots > .spot.-show-correctness.-in-area {
  background: rgba(68,204,68,0.5);
}
.HotSpotQuiz > .spots > .spot.-show-correctness:not(.-in-area) {
  background: rgba(255,68,68,0.5);
}
.button-style.-main {
  border-radius: 30px;
  border: 3px solid #4a267c;
  color: #fff;
  background-color: #4a267c;
  display: inline-block;
  padding: 6px 48px;
}
.button-style.-main.-hover:hover {
  background-color: #8067a3;
  border: 3px solid #8067a3;
}
.button-style.-secondary {
  border-radius: 30px;
  border: 3px solid #4a267c;
  color: #4a267c;
  background-color: #fff;
  display: inline-block;
  padding: 6px 48px;
}
.button-style.-secondary.-hover:hover {
  background-color: #4a267c;
  color: fff;
}
.button-style.-empty {
  border-radius: 30px;
  border: 3px solid #fff;
  color: #fff;
  display: inline-block;
  padding: 6px 48px;
}
.button-style.-empty.-hover:hover {
  background-color: #4a267c;
  color: fff;
}
.button-style.-answer {
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  background-color: #009c9a;
  border: 3px solid #009c9a;
  padding: 3px 8px;
}
.button-style.-answer._hidden {
  display: none;
}
.button-style.-answer .svg-icon {
  vertical-align: middle;
}
.button-style.-answer .text {
  display: inline-block;
  padding: 0px 20px;
}
.button-style.-answer.-hover:hover {
  background-color: #66c4c2;
  border: 3px solid #66c4c2;
  color: #fff;
}
.button-style.-selected {
  background-color: ;
  color: #fff;
}
.ChoiceQuiz ._hidden {
  display: none !important;
}
.ChoiceQuiz > .choices {
  list-style: none;
  margin: 0px;
}
.ChoiceQuiz > .choices > .choice {
  margin: 5px;
  display: inline-block;
}
.ChoiceQuiz > .choices > .choice.-show-correctness.-correct.-selected .button {
  background: ;
}
.ChoiceQuiz > .choices > .choice.-show-correctness.-correct:not(.-selected) .button {
  background: #f00;
  color: #fff;
}
.ChoiceQuiz > .choices > .choice.-show-correctness:not(.-correct).-selected .button {
  background: #f00;
  color: #fff;
}
.cover-image {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative;
  z-index: 5;
}
.cover-image >.background-image {
  height: calc(100vh - 60px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-color: #f0f0f0;
}
.cover-image .cloud-overlay {
  position: relative;
  top: -290px;
}
.cover-image .cloud-overlay .cloud {
  height: 220px;
  width: 100%;
}
.cover-image .cloud-overlay .cloud.-mobile {
  display: none;
}
@media all and (max-width: 44.125rem) {
  .cover-image .cloud-overlay .cloud.-mobile {
    display: block;
  }
  .cover-image .cloud-overlay .cloud.-desktop {
    display: none;
  }
}
/*
---
name: DropDown
category: UI/DropDown
tag:
 - component
 - dropdown
compile: true
---

A dropdown list to select one option out of many in the list.

## Example
```jade
div(view='DropDown',items='[[ [ { "label":"option 1", "value":1 }, { "label":"option 2", "value":2 }, { "label":"option 3", "value":3 } ] ]]')
```
*/
.drop-down {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  background-color: #fff;
  border: 1px solid #000;
  width: 100%;
  cursor: pointer;
}
.drop-down .button,
.drop-down .list {
  padding: 5px 30px 5px 7px;
}
.drop-down:not(.-open ) > .list {
  display: none;
}
.drop-down.-float {
  position: relative;
}
.drop-down.-float > .list {
  background-color: #fff;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  left: -1px;
  right: -1px;
  position: absolute;
  z-index: 1000;
}
.drop-down.-float > .list.-v-t-l {
  bottom: 100%;
}
.drop-down.-float > .list.-v-t-r {
  right: 0;
  bottom: 100%;
}
.drop-down.-float > .list.-h-t-r {
  right: 0;
  bottom: 100%;
  transform: translate(100%, 100%);
}
.drop-down.-float > .list.-h-b-r {
  right: 0;
  bottom: 0;
  transform: translate(100%, 0%);
}
.drop-down.-float > .list.-v-b-r {
  right: 0;
}
.drop-down.-float > .list.-h-t-l {
  top: 0;
  transform: translate(-100%, 0);
}
.drop-down.-float > .list.-h-b-l {
  bottom: 0;
  transform: translate(-100%, 0);
}
.element-layout {
  margin-right: auto;
  margin-left: auto;
  max-width: 706px;
}
@media all and (max-width: 44.125rem) {
  .element-layout {
    margin: 0 1rem;
  }
}
.fullscreen-image {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  z-index: 100;
  background-color: rgba(0,0,0,0.8);
}
.fullscreen-image >.image {
  max-width: 80%;
  margin: auto;
}
.fullscreen-image >.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
}
.HotSpotQuiz {
  position: relative;
  display: inline-block;
}
.HotSpotQuiz > .hotSpotAreas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea {
  position: absolute;
  border: 1px solid rgba(255,255,255,0.5);
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea:not(.-show-correctness) {
  display: none;
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea.-show-correctness.-selected {
  background: rgba(68,204,68,0.5);
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea.-show-correctness:not(.-selected) {
  background: rgba(255,68,68,0.5);
}
.HotSpotQuiz > .spots > .spot {
  position: absolute;
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(0,0,0,0.5);
}
.HotSpotQuiz > .spots > .spot:not(.-show-correctness) {
  background: rgba(0,0,0,0.5);
}
.HotSpotQuiz > .spots > .spot.-show-correctness.-in-area {
  background: rgba(68,204,68,0.5);
}
.HotSpotQuiz > .spots > .spot.-show-correctness:not(.-in-area) {
  background: rgba(255,68,68,0.5);
}
.info-modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
  left: 0px;
  padding: 20px;
  z-index: 50;
  background-color: rgba(0,0,0,0.8);
}
.info-modal >.inner {
  background-color: #fff;
}
.info-modal >.inner .close-button {
  text-align: right;
  cursor: pointer;
  color: #000;
}
.info-modal >.inner >.text {
  background-color: #fff;
}
@media print {
  .pdf-postcard {
    margin: 60px;
  }
  .pdf-postcard .image {
    width: 540px;
    height: 100%;
  }
  .pdf-postcard .sender {
    font-style: italic;
  }
}
.quiz-finished {
  position: fixed;
  top: 60px;
  right: 0px;
  max-width: 350px;
  z-index: 20;
  background-color: #009c9a;
  color: #fff;
}
.quiz-finished .animation {
  height: 190px;
  text-align: center;
}
.quiz-finished .animation >.animatedsmiley {
  width: 146px;
  height: 146px;
  animation: bounce 1s ease-in infinite;
}
.quiz-finished .animation .scale {
  animation: scale 1s ease-in infinite;
}
.quiz-finished .animation .shadow {
  position: absolute;
  width: 21%;
  height: 3%;
  opacity: 0.5;
  background: #007978;
  left: 40%;
  top: 43%;
  border-radius: 50%;
  z-index: 1;
}
@-moz-keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}
@-webkit-keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}
@-o-keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}
@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}
@-moz-keyframes scale {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes scale {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes scale {
  50% {
    transform: scale(0.9);
  }
}
@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}
.quiz-finished >.button {
  display: block;
  margin: 0 auto;
}
.story-imageelement {
  z-index: 10;
  position: relative;
  background-color: #f0f0f0;
}
.story-imageelement >.image {
  position: relative;
  top: 40px;
  z-index: 20;
}
@font-face {
  src: url("/css/BebasNeuePro-Bold/font.eot");
  font-family: 'BebasNeuePro-Bold';
  src: url("/css/BebasNeuePro-Bold/font.woff2") format('woff2'), url("/css/BebasNeuePro-Bold/font.woff") format('woff'), url("/css/BebasNeuePro-Bold/font.eot?#iefix") format('embedded-opentype'), url("/css/BebasNeuePro-Bold/font.ttf") format('truetype');
}
.text-style {
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.text-style a {
  border-bottom: 2px solid #000;
  padding: 0px 2px;
  cursor: pointer;
}
.text-style.-dark {
  color: #222;
}
.text-style.-violett {
  color: #4a267c;
}
.text-style.-green {
  color: #009c9a;
}
.text-style.-black {
  color: #000;
}
.text-style.-white {
  color: #fff;
}
.text-style.-caption {
  color: #6c6c6c;
}
.text-style.-center {
  text-align: center;
}
.text-style.-bold {
  font-weight: 700;
}
.text-style.-medium {
  font-weight: 500;
}
.text-style.-h1 {
  font-family: 'BebasNeuePro-Bold';
  font-size: 50px;
  line-height: 68px;
  letter-spacing: 1px;
}
.text-style.-h2 {
  font-family: 'Montserrat';
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.8px;
}
.text-style.-bebas {
  font-family: 'BebasNeuePro-Bold';
  line-height: 38px;
}
.text-style.-h3 {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
}
.text-style.-h4 {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}
.text-style.-p {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.text-style.-p.-bold,
.text-style.-p b {
  font-weight: 700;
}
.text-style.-p-small {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
.text-style.-p-small.-bold {
  font-weight: 700;
}
.text-style.-p-very-small {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
}
.text-style.-p-very-small.-bold {
  font-weight: 700;
}
.text-style.-h5 {
  font-family: 'Montserrat';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.7px;
}
.text-style.-h6 {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0px;
}
@media all and (max-width: 31.25rem) {
  .text-style.-h2 {
    font-size: 24px;
  }
  .text-style.-p {
    font-size: 16px;
  }
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Montserrat-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Montserrat-Medium.ttf") format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./Montserrat-SemiBold.ttf") format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Montserrat-Bold.ttf") format('truetype');
}
.resource-list-element .link:hover {
  color: #4a267c;
}
.resource-list-element .link:hover .text-style {
  color: #4a267c;
}
.resource-list-element .icon {
  width: 18px;
  height: 18px;
}
.footer-component {
  height: 147px;
  background-color: #fff;
  width: 100%;
}
.footer-component .logo {
  width: 162px;
}
.footer-component .links .company,
.footer-component .links .link {
  display: inline-block;
}
.footer-component .links .link {
  border: none;
}
.footer-component .links .link:hover {
  color: #4a267c;
  cursor: pointer;
}
@media all and (max-width: 44.125rem) {
  .footer-component .links > .company {
    display: none;
  }
}
.Image > img {
  max-width: 100%;
}
.main-navigation {
  position: sticky;
  background-color: #fff;
  top: 0px;
  width: 100%;
  height: 60px;
  color: #4a267c;
  z-index: 20;
}
.main-navigation.-scrolling {
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
}
.main-navigation.-scrolling .logo {
  visibility: visible;
}
.main-navigation .logo {
  visibility: hidden;
  margin-left: 30px;
}
.main-navigation .logo .svg-icon {
  width: 264px;
}
.main-navigation .back {
  cursor: pointer;
}
.main-navigation .back ._hidden {
  display: none;
}
.main-navigation .languages {
  position: relative;
  margin-right: 16px;
}
.main-navigation .languages ._hidden {
  display: none;
}
.main-navigation .languages:hover {
  cursor: pointer;
}
.main-navigation .languages.-open .menu {
  position: absolute;
  text-align: left;
  background-color: #fff;
  width: 100%;
}
.main-navigation .navi-button {
  margin-right: 16px;
  font-size: 1.5rem;
}
.main-navigation .navi-button:hover {
  cursor: pointer;
}
.main-navigation .navi-button .svg-icon {
  width: 32px;
  height: 32px;
}
.main-navigation .navi-close-button {
  text-align: right;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100vh;
  padding-top: 16px;
  padding-right: 16px;
  background-color: #4a267c;
}
.main-navigation .navi-close-button:hover {
  cursor: pointer;
}
.main-navigation .navi-close-button >.items >.item {
  text-align: left;
  height: 54px;
  line-height: 54px;
  padding-left: 32px;
  padding-right: 16px;
  margin-right: -16px;
}
.main-navigation .navi-close-button >.items >.item:hover {
  cursor: pointer;
  background-color: #8067a3;
}
.main-navigation.-open {
  display: block;
}
.main-navigation.-open.navi-close-button {
  display: block;
}
.main-navigation.-closed >.navi-close-button {
  display: none;
}
.postcard .link {
  border-bottom: 1px solid #000;
  display: inline;
  cursor: pointer;
}
.postcard .modal {
  z-index: 100;
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #4a267c;
  overflow-y: auto;
}
.postcard .modal._hidden {
  display: none;
}
.postcard .modal >.inner {
  background-color: #fff;
  margin-top: 100px;
  min-height: 80vh;
  overflow-y: auto;
}
.postcard .modal >.inner >.close-button {
  text-align: right;
  cursor: pointer;
}
.ChoiceQuiz >.choices >.choice {
  display: flex;
  cursor: pointer;
}
.ChoiceQuiz >.choices >.choice .text,
.ChoiceQuiz >.choices >.choice .text > p {
  display: block;
  margin-left: 14px;
}
.ClozeQuiz {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.ClozeQuiz .choice.-show-correctness.-correct.-selected {
  background: #4c4;
}
.ClozeQuiz .choice.-show-correctness.-correct:not(.-selected) {
  background: #f44;
  color: #fff;
}
.ClozeQuiz .choice.-show-correctness:not(.-correct).-selected {
  background: #f44;
  color: #fff;
}
.ClozeQuiz .SelectSpan .choices.-show-correctness.-correct,
.SelectSpan .choices.-show-correctness.-correct {
  background-color: #e5f5f5;
}
.ClozeQuiz .SelectSpan .choices.-show-correctness.-correct .drop-down,
.SelectSpan .choices.-show-correctness.-correct .drop-down {
  border-color: #009c9a;
  background-color: #e5f5f5;
}
.ClozeQuiz .SelectSpan .choices.-show-correctness.-correct .drop-down .list,
.SelectSpan .choices.-show-correctness.-correct .drop-down .list {
  background-color: #e5f5f5;
  border-color: #009c9a;
}
.ClozeQuiz .SelectSpan .choices.-show-correctness:not(.-correct),
.SelectSpan .choices.-show-correctness:not(.-correct) {
  background-color: #fbecea;
}
.ClozeQuiz .SelectSpan .choices.-show-correctness:not(.-correct) .drop-down,
.SelectSpan .choices.-show-correctness:not(.-correct) .drop-down {
  border-color: #e30a18;
  background-color: #fbecea;
}
.ClozeQuiz .SelectSpan .choices.-show-correctness:not(.-correct) .drop-down .list,
.SelectSpan .choices.-show-correctness:not(.-correct) .drop-down .list {
  border-color: #e30a18;
  background-color: #fbecea;
}
.TextChoice .svg-icon {
  vertical-align: sub;
}
.TextChoice.-show-correctness {
  background-color: #fbecea;
}
.TextChoice.-show-correctness.-correct {
  background-color: #e5f5f5;
}
.TextChoice .input,
.TextChoice .right,
.TextChoice .wrong {
  display: none;
}
.TextChoice .input.-selected {
  display: inline;
}
.TextChoice .default.-selected {
  display: none;
}
.TextChoice.-show-correctness .default,
.TextChoice.-show-correctness .input,
.TextChoice.-show-correctness .right {
  display: none;
}
.TextChoice.-show-correctness .wrong {
  display: inline;
  background-color: #fbecea;
}
.TextChoice.-show-correctness.-correct .wrong {
  display: none;
}
.TextChoice.-show-correctness.-correct .right {
  display: inline;
  background-color: #e5f5f5;
}
.quiz-presenter .info-text {
  border-bottom: 2px solid #000;
  padding: 0px 2px;
  cursor: pointer;
}
.quiz-presenter .image-container {
  position: relative;
  display: inline-block;
}
.quiz-presenter .image-container._hidden {
  display: none;
}
.quiz-presenter .image-container .quiz-image {
  max-width: 100%;
  max-height: 424px;
}
.quiz-presenter .image-container .magnify >.svg-icon {
  color: rgba(255,255,255,0.6);
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
  width: 42px;
  height: 42px;
}
.quiz-presenter .dialog >.correct {
  color: #009c9a;
  border: 1px solid #009c9a;
  background-color: #e5f5f5;
}
.quiz-presenter .dialog >.incorrect {
  color: #e30a18;
  border: 1px solid #e30a18;
  background-color: #fbecea;
}
.quiz-presenter .dialog .smiley {
  width: 42px;
  height: 42px;
  fill: currentColor;
}
.quiz-presenter .dialog .text {
  display: inline-block;
}
.quiz-presenter .dialog >.noanswer {
  border: 1px solid #e30a18;
  background-color: #fbecea;
}
.quiz-presenter .quiz-header .close {
  cursor: pointer;
  color: #fff;
  width: 27px;
  height: 27px;
}
.single-page {
  padding-top: 156px;
  background-color: #f0f0f0;
}
.SolutionInput .answer.-show-correctness {
  border-color: #e30a18;
  background-color: #fbecea;
}
.SolutionInput .answer.-show-correctness.-correct {
  border-color: #009c9a;
  background-color: #e5f5f5;
}
.start-page {
  background-color: #f0f0f0;
}
.start-page >.header {
  text-align: right;
  background-color: #fff;
}
.start-page .story-elements {
  margin-top: -100px;
  position: relative;
  z-index: 10;
}
.start-page .story-elements >.item {
  text-align: center;
  cursor: pointer;
  margin-bottom: 80px;
}
.start-page .story-elements >.item .description {
  height: 104px;
}
.start-page .story-elements >.item .image-container {
  margin: 0px auto 24px auto;
  border-radius: 50%;
  width: 308px;
  height: 308px;
  overflow: hidden;
}
.story-image {
  padding-top: 60px;
  position: relative;
  z-index: 10;
}
.story-image >.image-responsive {
  width: 100vw;
}
.story-page {
  background-color: #f0f0f0;
  position: relative;
}
.story-page >.header {
  position: sticky;
  top: 0px;
  z-index: 100;
  height: 60px;
  width: 100%;
  cursor: pointer;
  background-color: #4a267c;
  color: #fff;
}
.story-page >.header .svg-icon {
  color: #fff;
  width: 30px;
  height: 30px;
  vertical-align: sub;
}
.story-page >.header._hidden {
  display: none;
}
.story-page .vertical-line {
  height: 100%;
  border: 2px solid #fff;
  top: 0;
  left: 50%;
  position: absolute;
}
.story-page .background-hack {
  display: none;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0px;
  background-color: #f0f0f0;
  z-index: 5;
}
.story-page .chapter:last-child {
  padding-bottom: 100px;
}
.story-page .chapter:last-child .background-hack {
  display: block;
}
.story-page .chapter:nth-child(1)._hidden,
.story-page .chapter:nth-child(2)._hidden {
  display: block;
}
.point-navigation >.point {
  margin: 5px;
}
.point-navigation >.point.-teacher {
  cursor: pointer;
}
.point-navigation >.point.svg-icon.-current {
  width: 30px;
  height: 30px;
}
@media all and (max-width: 44.125rem) {
  .point-navigation >.point {
    margin: 2px;
  }
  .point-navigation >.point.svg-icon {
    width: 16px;
    height: 16px;
  }
  .point-navigation >.point.svg-icon.-current {
    width: 20px;
    height: 20px;
  }
}
.quiz-button .svg-icon.-animate {
  animation: pulse 2s infinite;
  border-radius: 50%;
  border: 1px solid #4a267c;
  margin: 25px;
  background-color: #4a267c;
}
.quiz-button .svg-icon.-animate:hover {
  animation: none;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(74,37,124,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(74,37,124,0);
  }
}
@-moz-keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
    box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(74,37,124,0);
    box-shadow: 0 0 0 20px rgba(74,37,124,0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(74,37,124,0);
    box-shadow: 0 0 0 0 rgba(74,37,124,0);
  }
}
@-webkit-keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
    box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(74,37,124,0);
    box-shadow: 0 0 0 20px rgba(74,37,124,0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(74,37,124,0);
    box-shadow: 0 0 0 0 rgba(74,37,124,0);
  }
}
@-o-keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
    box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(74,37,124,0);
    box-shadow: 0 0 0 20px rgba(74,37,124,0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(74,37,124,0);
    box-shadow: 0 0 0 0 rgba(74,37,124,0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
    box-shadow: 0 0 0 0 rgba(74,37,124,0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(74,37,124,0);
    box-shadow: 0 0 0 20px rgba(74,37,124,0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(74,37,124,0);
    box-shadow: 0 0 0 0 rgba(74,37,124,0);
  }
}
.quiz-navigation {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  height: 70px;
  background-color: #fff;
  box-shadow: 0 -1px 6px 0 rgba(0,0,0,0.1);
}
.quiz-navigation .button-style {
  float: right;
}
.quiz-navigation >.next {
  text-align: right;
  margin-right: 24px;
}
.quiz-navigation >.prev,
.quiz-navigation >.next {
  display: inline-block;
  cursor: pointer;
  padding: 10px;
}
.quiz-navigation >.prev.-disabled,
.quiz-navigation >.next.-disabled {
  visibility: hidden;
}
@media all and (max-width: 31.25rem) {
  .quiz-navigation .point-navigation {
    display: none;
  }
  .quiz-navigation .item {
    width: 100%;
    text-align: center;
  }
  .quiz-navigation .item .button-style {
    margin: 0 auto;
    float: none;
  }
}
.quiz {
  z-index: 20;
}
.quiz.-open {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  min-height: 100vh;
  background-color: #4a267c;
  overflow-y: auto;
}
.quiz.-close {
  display: none;
}
.quiz .inner-box {
  padding: 32px 24px 32px 24px;
  overflow-y: auto;
  position: absolute;
  margin-top: 60px;
  margin-bottom: 60px;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 30px;
  background-color: #fff;
}
.quiz .quiz-header {
  z-index: 10;
  height: 60px;
  color: #fff;
  position: fixed;
  width: 100%;
  background-color: #4a267c;
}
.quiz .quiz-header .title {
  text-align: center;
}
.quiz .quiz-header .close {
  cursor: pointer;
}
.quiz .quiz-header .close {
  width: 27px;
  height: 27px;
}
.story-quiz .quiz-button {
  position: relative;
  text-align: center;
  z-index: 10;
  cursor: pointer;
}
.story-quiz .quiz-button.-unsolved .quiz-open {
  display: inline-block;
}
.story-quiz .quiz-button.-unsolved .quiz-completed {
  display: none;
}
.story-quiz .quiz-button.-completed .quiz-open {
  display: none;
}
.story-quiz .quiz-button.-completed .quiz-completed {
  display: inline-block;
}
.story-quiz .quiz-button >.svg-icon {
  width: 6.25rem;
  height: 6.25rem;
}
.story-quiz .quiz-button .explanation-container {
  display: block;
  margin-top: 7px;
}
.story-quiz .quiz-button .explanation-container .explanation {
  background-color: #009c9a;
  display: inline-block;
  border-radius: 6px;
  box-shadow: 0 1px 12px 0 rgba(0,0,0,0.05);
  width: 245px;
  padding: 8px 16px;
}
.story-quiz .quiz-button .explanation-container._hidden {
  display: none;
}
.story-solution {
  position: relative;
  padding-top: 150px;
}
.story-solution ._hidden {
  display: none;
}
.story-solution >.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 12px 0 rgba(0,0,0,0.05);
  padding-top: 40px;
  margin: 0px auto;
  z-index: 5;
  position: relative;
}
.story-text {
  padding-top: 192px;
  position: relative;
}
.story-text >.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 12px 0 rgba(0,0,0,0.05);
  padding-top: 40px;
  margin: 25px auto;
  z-index: 5;
  position: relative;
}
.story-text >.container .description .text-style.-p {
  line-height: 30px;
}
.audioplayer {
  text-align: right;
  line-height: 0;
}
.audioplayer.-playing .audio-start {
  display: none;
}
.audioplayer.-playing .audio-pause {
  display: inline-block;
}
.audioplayer.-stopped .audio-start {
  display: inline-block;
}
.audioplayer.-stopped .audio-pause {
  display: none;
}
.audioplayer >.svg-icon {
  width: 3rem;
  height: 3rem;
  color: #4a267c;
  cursor: pointer;
}
.audioplayer >.svg-icon.-hover {
  color: #f00;
}
.Placeholder {
  display: inline-block;
  background-color: #eee;
  border: dashed 0.0625rem #888;
  color: #888;
  padding: 0.625rem 0.9375rem;
  margin: 0.75rem 0 0.75rem;
}
.Placeholder:after {
  display: inline;
  content: ' (not implemented)';
}
