.ChoiceQuiz {

  ._hidden {
    display:none !important;
  }

  &.-multiple {
  }
  &.-single {
  }
  > .choices {
    list-style: none;
    margin: 0px;

    
    > .choice {
      margin: 5px;
      display: inline-block;
      
      &.-show-correctness.-correct.-selected .button{
        background: theme.colors.lightgreen;
      }
      &.-show-correctness.-correct:not(.-selected) .button{
        background: red;
        color: #fff;
      }
      &.-show-correctness:not(.-correct).-selected .button{
        background: red;
        color: #fff;
      }
    }
  }
}
