.footer-component {
    height: 147px;
    // background-color: theme.colors.main-green;
    background-color: #fff;
    width: 100%;
    // color: #fff;

    .logo {
        width: 162px;
    }

    .links {
        
        .company, .link {
            display: inline-block;
        }
        
        .link {
            border: none;
            &:hover {
                color: theme.colors.main-purple;
                cursor: pointer;
            }
        }

    }

}

@media all and ( max-width: theme.settings.breakpoints.small ) {
    .footer-component {
        .links > .company {
            display:none;
        }
    }

}