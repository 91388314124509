.postcard {

    .link {
        border-bottom: 1px solid black;
        //text-decoration: underline;
        display: inline;
        cursor: pointer;
    }
    .modal {
        &._hidden {
            display:none;
        }
        z-index: 100;
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        
        background-color: #4a267c;
        overflow-y: auto;

        >.inner {
            background-color: #fff;
            margin-top:100px;
            min-height: 80vh;
            overflow-y: auto;

            >.close-button {
                text-align: right;
                cursor: pointer;
            }


            
        }
    }


}