.TextChoice {
    .svg-icon {
        vertical-align:sub;
    }
    &.-show-correctness {
        background-color: theme.colors.wrong-red-two;
        &.-correct {
            background-color: theme.colors.main-green-two;
        }
    }
    .input, .right, .wrong {
        display: none;
    }
    
    .input {
        &.-selected {
            display: inline;
        }
    }
    
    .default {
        &.-selected {
            display: none;
        }
    }

    &.-show-correctness {
        .default, .input, .right {
            display: none;
        }
        .wrong {
            display: inline;
            background-color: theme.colors.wrong-red-two;
        }

        &.-correct {
            .wrong {
                display: none;
            }
            .right {
                display: inline;
                background-color: theme.colors.main-green-two;
            }
        }
    }

}