


// @font-face {
//     font-family: 'BebasNeuePro-Bold';
//     src: url('/css/fonts/Bebas_Neue_Pro_Bold.eot');
//     src: url('/css/fonts/Bebas_Neue_Pro_Bold.eot?#iefix') format('embedded-opentype'),
//     url('/css/fonts/Bebas_Neue_Pro_Bold.woff2') format('woff2'),
//     url('/css/fonts/Bebas_Neue_Pro_Bold.woff') format('woff'),
//     url('/css/fonts/Bebas_Neue_Pro_Bold.ttf') format('truetype'),
//     url('/css/fonts/BebasNeuePro-Bold.svg#BebasNeuePro-Bold') format('svg');
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('/css/fonts/subset-Montserrat-Regular.woff2') format('woff2');
//   //unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// <link href="https://fonts.googleapis.com/css?family=Montserrat&display=swap" rel="stylesheet">
// @font-face {
//     font-family: 'Montserrat';
//     src: url('/css/fonts/subset-Montserrat-Regular.eot');
//     src: url('/css/fonts/subset-Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
//         url('/css/fonts/subset-Montserrat-Regular.woff2') format('woff2'),
//         url('/css/fonts/subset-Montserrat-Regular.woff') format('woff'),
//         url('/css/fonts/subset-Montserrat-Regular.ttf') format('truetype'),
//         url('/css/fonts/subset-Montserrat-Regular.svg#Montserrat-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }


// @import url("//hello.myfonts.net/count/3b3730");  


@font-face {
  src: url('/css/BebasNeuePro-Bold/font.eot');
  font-family: 'BebasNeuePro-Bold';
  src: url('/css/BebasNeuePro-Bold/font.woff2') format('woff2'), url('/css/BebasNeuePro-Bold/font.woff') format('woff'), url('/css/BebasNeuePro-Bold/font.eot?#iefix') format('embedded-opentype'), url('/css/BebasNeuePro-Bold/font.ttf') format('truetype');
}



.text-style {
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;

    a {
        border-bottom: 2px solid #000;
        padding: 0px 2px;
        cursor: pointer;
    }
    

    &.-dark {
        color: theme.colors.text-black;
    }
    &.-violett {
        color: theme.colors.main-purple;
    }

    &.-green {
        color: theme.colors.main-green;
    }

    //new (second variant dark)
    &.-black {
        color: #000;
    }
    &.-white {
        color: #fff;
    }

    &.-caption {
        color: theme.colors.text-grey;
    }

    &.-center {
        text-align: center;
    }


    &.-bold {
        font-weight: 700;
    }

    &.-medium {
        font-weight: 500;
    }

    
    
    &.-h1 {
        font-family: 'BebasNeuePro-Bold';
        font-size: 50px;
        line-height: 68px;
        letter-spacing: 1px;
    }

    &.-h2 {
        font-family: 'Montserrat';
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.8px;
    }

    &.-bebas {
        font-family: 'BebasNeuePro-Bold';
        line-height: 38px;
    }

    //ab nun gut?
    &.-h3 {
        font-family: 'Montserrat';
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0px;
    }

    &.-h4 {
        font-family: 'Montserrat';
        font-weight:500;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }

    &.-p {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        &.-bold, b {
            font-weight: 700;
        }
    }

    &.-p-small {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        &.-bold {
            font-weight: 700;
        }
    }

    &.-p-very-small {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        
        &.-bold {
            font-weight: 700;
        }
    }



    &.-h5 {
        font-family: 'Montserrat';
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.7px;
    }

    &.-h6 {
        font-family: 'Montserrat';
        font-weight:400;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: 0px;
    }


@media all and ( max-width: theme.settings.breakpoints.xsmall ) {
    &.-h2 {
        font-size: 24px;
    }

    &.-p {
        font-size: 16px;
    }


}



}