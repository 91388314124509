.SolutionInput {
    .answer {
        &.-show-correctness {
            border-color: theme.colors.wrong-red;
            background-color: theme.colors.wrong-red-two;
            &.-correct {
                border-color: theme.colors.main-green;
                background-color: theme.colors.main-green-two;
            }
        }
    }
}