@media print {

    .pdf-postcard {
        margin: 60px;
        .image {
            // width: 100%; //1412x848
            width: 540px;
            height:100%;
        }

        .sender {
            font-style: italic;
        }
    }

}