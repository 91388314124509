.start-page {
    background-color: theme.colors.background-light-pink;

    >.header {
        text-align:right;
        background-color:#fff;
    }

    .story-elements {
        margin-top: -100px;
        position: relative;
        z-index: 10;

        >.item {
            text-align: center;
            cursor: pointer;
            margin-bottom: 80px;
                
            .description {
                height: 104px;
            }
     
            .image-container {
                margin: 0px auto 24px auto;
                border-radius: 50%;
                width: 308px;
                height: 308px;
                overflow: hidden;
            }
        }
    }
}

@media all and ( max-width: theme.settings.breakpoints.small ) {
    // .start-page {
    //     .story-elements {
    //         margin-top: -200px;
    //     }
    // }

}
