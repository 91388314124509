.element-layout {
    margin-right: auto;
    margin-left: auto;
    //padding: 0 1rem;
    max-width: 706px;
}


@media all and ( max-width: theme.settings.breakpoints.small ) {
    .element-layout {
        margin: 0 rem(16px);
    }

}

