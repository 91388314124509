
//only used for first large image
.story-image {
    padding-top:60px;
    position: relative;
    z-index: 10;
    >.image-responsive {
        width: 100vw;
    }
}

