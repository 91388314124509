.quiz-navigation {
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    height: 70px;
    
    background-color: #fff;
    box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.1);

    .button-style {
        float: right;
    }




    
    >.next {
        text-align:right;
        margin-right: 24px;
    }
    
    >.prev, >.next {
        display: inline-block;
        cursor:pointer;
        
        padding: 10px;

        &.-disabled {
            
            visibility:hidden;
        }
    }
}

@media all and ( max-width: theme.settings.breakpoints.xsmall ) {
    .quiz-navigation {
        .point-navigation {
            display:none;
        }
        .item {
            width:100%;
            text-align:center;
                .button-style {
                    margin: 0 auto;
                    float: none;
                }

        }

    }
}

