.resource-list-element {

    .link {
        &:hover {
            color: theme.colors.main-purple;

            .text-style {
                color: theme.colors.main-purple;
            }
        }
    }

    .icon {
        width: 18px;
        height: 18px;
    }
}