.story-text {
    padding-top:192px;
    position: relative;

    >.container {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.05);
        padding-top: 40px;
        margin: 25px auto;
        z-index: 5;
        position:relative;

        .description {
            .text-style {
                &.-p{
                    line-height: 30px;
                }
            }
        }
    }

}