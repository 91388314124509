.quiz-finished {

    position: fixed;
    top: 60px;
    right: 0px;
    max-width: 350px;
    z-index: 20;

    background-color: theme.colors.main-green;
    color: #fff;

    .animation {
        height:190px;
        text-align:center;
        >.animatedsmiley {
            width: 146px;
            height: 146px;
            animation: bounce 1s ease-in infinite;
        }

        .scale {
            animation: scale 1s ease-in infinite;
        }


        .shadow {
            position: absolute;
            width: 21%;
            height: 3%;
            opacity: .5;
            background: #007978;
            left: 40%;
            top: 43%;
            border-radius: 50%;
            z-index: 1;
        }

        @keyframes bounce {
            50% {
                transform: translateY(-10px);
            }
        }

        @keyframes scale {
            50% {
                transform: scale(0.9);
            }
        }

    }



    >.text {

    }
    >.button {
        display: block;
        margin: 0 auto;
    }





}