.story-imageelement{
    z-index: 10;
    position: relative;
    background-color: theme.colors.background-light-pink;
    >.image {
        position: relative;
        top: 40px;
        z-index: 20;
        //max-height: 260px;
    }
}